<template>
  <div class="base" style="min-width: 1900px;">
    <div class="base_head scaleBanner">
      <div class="base_head_img"></div>
      <div class="base_head_content">
        <div class="base_head_content_title moveTopTitleDiff1">
          {{ companyItem.companyName
          }}{{
            !this.$route.query.id
              ? ""
              : this.$route.query.id == 4
              ? "能源"
              : "药材"
          }}基地中心
        </div>
        <div class="base_head_content_tips moveTopTitleDiff2">
          {{ companyItem.companyDesc }}
        </div>
        <div class="base_head_content_num moveTopTitleDiff3">
          <div class="base_head_content_num1" id="ZZMJ"></div>
          <div class="base_head_content_num2" id="GXRY"></div>
          <div class="base_head_content_num3" id="JGZD"></div>
        </div>
        <div class="base_head_content_desc moveTopTitleDiff3">
          <div class="base_head_content_desc1">
            {{ this.$route.query.id == 4 ? "覆盖面积(亩)" : "种植面积(亩)" }}
          </div>
          <div class="base_head_content_desc2">工作人员(人)</div>
          <div class="base_head_content_desc3">基地数量(个)</div>
        </div>
      </div>
    </div>
    <div class="base_hegang">
      <div
        :class="{ active1: typeId == item.id }"
        style="flex: 1; background: #143f22"
        v-for="(item, index) in listType"
        :key="index"
      >
        <div style="opacity: 0.55" class="font1" @click="typeIdCK(item)">
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="base_child_info">
      <div class="base_child_info_title">
        <div class="center_title">
          <img
            src="../../../static/newImg2/home.png"
            width="16px"
            @click="toPage()"
          />
          <span class="center_home"> > 基地中心</span>
        </div>
        <div class="slect_list moveTopNormal">
          <el-select
            v-model="projectType1"
            placeholder="暂无选择相关公司"
            @change="changeProjectType1"
            no-data-text="暂无相关公司"
          >
            <el-option :value="3" label="全国"></el-option>
            <el-option
              :value="item.companyId"
              :label="item.companyName"
              v-for="item in projectType1Arr"
              :key="item.companyId"
            ></el-option>
          </el-select>
          <el-select
            v-model="projectType2"
            placeholder="暂无选择相关公司"
            @change="changeProjectType2"
            no-data-text="暂无相关公司"
          >
            <el-option
              :value="item.companyId"
              :label="item.companyName"
              v-for="item in projectType2Arr"
              :key="item.companyId"
            ></el-option>
          </el-select>
          <el-select
            v-model="projectType3"
            placeholder="暂无选择相关公司"
            @change="changeProjectType3"
            no-data-text="暂无相关公司"
          >
            <el-option
              :value="item.companyId"
              :label="item.companyName"
              v-for="item in projectType3Arr"
              :key="item.companyId"
            ></el-option>
          </el-select>
          <el-button
            type="success"
            style="background-color: #128d4a; width: 160px"
            @click="getChildProjectList()"
            >查询</el-button
          >
        </div>
      </div>
      <div class="base_child_info_project" v-if="childProjectList.length > 0">
        <div
          class="base_child_info_project_item moveTopNormal"
          v-for="item in childProjectList"
          :key="item.projectId"
          @click="toProject(item)"
        >
          <div class="base_child_info_project_item_img">
            <img :src="item.projectImage" width="100%" />
            <div
              class="base_child_info_project_item_img_none"
              v-if="item.projectId == -1"
            >
              {{ item.projectName }}
            </div>
          </div>
          <div
            class="base_child_info_project_item_tips"
            v-if="item.projectId != -1"
          >
            <div
              class="base_child_info_project_item_tips_left"
              style="font-weight: bold"
            >
              {{ item.projectName }}
            </div>
            <div
              class="base_child_info_project_item_tips_left"
              style="font-size: 16px; line-height: 30px; margin-top: 12px"
            >
              项目时间：{{ item.projectDate.split(",")[0] }} 至
              {{ item.projectDate.split(",")[1] }}
            </div>
            <div
              class="base_child_info_project_item_tips_left"
              style="
                font-size: 16px;
                line-height: 30px;
                margin-top: 16px;
                margin-bottom: 21px;
              "
            >
              所属单位：浙江中峪道地药材有限公司
            </div>
            <div
              class="base_child_info_project_item_tips_left"
              style="font-size: 16px; line-height: 30px"
            >
              <!-- 产品介绍：<br /> -->
              <div class="base_child_info_project_item_tips_right">
                {{ item.projectDesc }}
              </div>
            </div>
            <div class="base_child_info_project_item_tips_more">
              了解详情<img
                src="../../../static/v1.0/21.png"
                width="16px"
                height="16px"
              />
            </div>
            <div class="base_child_info_project_item_tips_line"></div>
          </div>
        </div>
      </div>
      <div
        class="base_child_info_project"
        v-if="childProjectList.length == 0"
        style="text-align: center;display: block;"
      >
        <img
          src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-19/dce89a55ac694fecba87dbe7f715a5af.png"
          width="700px"
          style="margin-top: 20px;"
        />
        <div
          style="width: 100%;font-weight: bold;font-size: 26px;color: #c9c9c9;line-height: 39px;"
        >
          更多基地正在开发中···
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getSignature,
  getProject,
  getCompanyList,
  getStatistic,
  getCompanyDetailById,
} from "@/api/index.js";
export default {
  name: "Base",
  data() {
    return {
      childProjectList: [],
      companyId: 3,
      projectType1: 3,
      projectType2: "",
      projectType3: "",
      projectType1Arr: [],
      projectType2Arr: [],
      projectType3Arr: [],
      typeId: 1,
      listType: [
        {
          name: "种植基地",
          id: 1,
        },
        {
          name: "种子基地",
          id: 0,
        },
        {
          name: "种苗基地",
          id: 2,
        },
        {
          name: "科研基地",
          id: 3,
        },
        {
          name: "能源基地",
          id: 4,
        },
      ],
      companyItem: {},
      statisticInfo: {},
    };
  },
  mounted() {
    let company;
    if (this.$route.query.companyId) {
      getCompanyDetailById(this.$route.query.companyId).then((res) => {
        company = res.data;
        this.$store.commit("setCompany", company);
        this.companyItem = company;
        if (this.$route.query.id) {
          this.typeId = Number(this.$route.query.id);
        }
        this.companyId = this.companyItem.companyId;
        window.scrollTo(0, 0);
        setTimeout(() => {
          document.getElementsByClassName(
            "moveTopTitleDiff1"
          )[0].style.opacity = 1;
          document.getElementsByClassName(
            "moveTopTitleDiff2"
          )[0].style.opacity = 1;
          document.getElementsByClassName(
            "moveTopTitleDiff3"
          )[0].style.opacity = 1;
          document.getElementsByClassName(
            "moveTopTitleDiff3"
          )[1].style.opacity = 1;
        }, 1000);
        this.getChildProjectList();
        this.getCompanyList();
        this.getStatistic();
        this.getWXInfo();
      });
    } else {
      company = this.$store.getters.getCompany;
      this.companyItem = company;
      if (this.$route.query.id) {
        this.typeId = Number(this.$route.query.id);
      }
      this.companyId = this.companyItem.companyId;
      window.scrollTo(0, 0);
      setTimeout(() => {
        document.getElementsByClassName(
          "moveTopTitleDiff1"
        )[0].style.opacity = 1;
        document.getElementsByClassName(
          "moveTopTitleDiff2"
        )[0].style.opacity = 1;
        document.getElementsByClassName(
          "moveTopTitleDiff3"
        )[0].style.opacity = 1;
        document.getElementsByClassName(
          "moveTopTitleDiff3"
        )[1].style.opacity = 1;
      }, 1000);
      this.getChildProjectList();
      this.getCompanyList();
      this.getStatistic();
      this.getWXInfo();
    }
  },
  methods: {
    changeProjectType1() {
      this.projectType3 = "";
      this.projectType2 = "";
      this.companyId = this.projectType1;
      getCompanyList({
        companyLevel: 3,
        current: 1,
        pid: this.companyId,
        size: 100,
      }).then((res) => {
        this.projectType2Arr = res.data.list;
      });
    },
    changeProjectType2() {
      this.projectType3 = "";
      this.companyId = this.projectType2;
      getCompanyList({
        companyLevel: 4,
        current: 1,
        pid: this.companyId,
        size: 100,
      }).then((res) => {
        this.projectType3Arr = res.data.list;
      });
    },
    changeProjectType3() {
      this.companyId = this.projectType3;
    },
    getCompanyList() {
      getCompanyList({
        companyLevel: 2,
        current: 1,
        websiteId: 3,
        size: 100,
      }).then((res) => {
        this.projectType1Arr = res.data.list;
        this.projectType1 = this.companyId;
      });
    },
    getChildProjectList() {
      getProject({
        companyId: this.companyId,
        projectType: this.typeId,
        withChildCompany: 1,
        current: 1,
        size: 10,
      }).then((res) => {
        this.childProjectList = res.data.list;
        if (
          /Mobile|Android|iPhone|iPad|iPod|Windows Phone/i.test(
            navigator.userAgent
          )
        ) {
          if (this.childProjectList.length == 0) {
            document.getElementsByClassName(
              "base_child_info_project"
            )[0].style =
              "min-height: 1500px;text-align: center;display: block;";
          } else {
            document.getElementsByClassName(
              "base_child_info_project"
            )[0].style = "min-height: 1500px;";
          }
        }
        this.$nextTick(() => {
          const arr = document.querySelectorAll(
            ".base_child_info_project_item"
          );
          arr.forEach((val) => {
            val.className = "base_child_info_project_item moveTopNormal";
            setTimeout(() => {
              val.className =
                "base_child_info_project_item moveTopNormal moveTopAnimation";
            }, 500);
          });
        });
      });
    },
    typeIdCK(item, index) {
      this.typeId = item.id;
      this.getChildProjectList();
    },
    toProject(item) {
      if (item.projectId == -1) {
        return;
      }
      this.$router.push({
        path: "/projectDetail",
        query: { id: item.projectId },
      });
    },
    getStatistic() {
      getStatistic(this.companyId).then((res) => {
        const data = res.data;
        if (!this.$route.query.id) {
          this.statisticInfo = {
            plantedNumber:
              data[0].plantedNumber +
              data[1].plantedNumber +
              data[2].plantedNumber,
            projectPersonnel:
              data[0].projectPersonnel +
              data[1].projectPersonnel +
              data[2].projectPersonnel,
            projectBaseNumber:
              data[0].projectBaseNumber +
              data[1].projectBaseNumber +
              data[2].projectBaseNumber,
          };
        } else if (this.$route.query.id == 4) {
          this.statisticInfo = {
            plantedNumber: data[1].plantedNumber + data[2].plantedNumber,
            projectPersonnel:
              data[1].projectPersonnel + data[2].projectPersonnel,
            projectBaseNumber:
              data[1].projectBaseNumber + data[2].projectBaseNumber,
          };
        } else {
          this.statisticInfo = {
            plantedNumber: data[0].plantedNumber + data[2].plantedNumber,
            projectPersonnel:
              data[0].projectPersonnel + data[2].projectPersonnel,
            projectBaseNumber:
              data[0].projectBaseNumber + data[2].projectBaseNumber,
          };
        }
        this.allCountUp();
      });
    },
    allCountUp() {
      new this.$countUp("ZZMJ", this.statisticInfo.plantedNumber).start();
      new this.$countUp("GXRY", this.statisticInfo.projectPersonnel).start();
      new this.$countUp("JGZD", this.statisticInfo.projectBaseNumber).start();
    },
    getWXInfo() {
      const that = this;
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp;
        const nonceStr = res.data.noncestr;
        const signature = res.data.signature;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: "wxad8dd02ae97d307f", // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
        });
        wx.ready(function() {
          //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: that.companyItem.companyName + "基地中心", // 分享标题
            desc: that.companyItem.companyDesc, // 分享描述
            link:
              window.location.href +
              "?companyId=" +
              that.companyItem.companyId +
              "&companyArr=" +
              JSON.stringify(that.$store.getters.getCompanyTier), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl:
              "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-29/e60fabe8d26d4650924f8087c8fc965c.jpg", // 分享图标
          };
          wx.updateAppMessageShareData(obj);
          wx.updateTimelineShareData(obj);
        });
      });
    },
    toPage() {
      const company = this.$store.getters.getCompany;
      if (company.companyLevel == 1) {
        this.$router.push({ path: `/` });
      }
      if (company.companyLevel == 2) {
        this.$router.push({ path: `/ProvincialCompany` });
      }
      if (company.companyLevel == 3) {
        this.$router.push({ path: `/cityCompany` });
      }
      if (company.companyLevel == 4) {
        this.$router.push({ path: `/areaCompany` });
      }
    },
  },
  watch: {
    $route() {
      if (this.$route.query.id) {
        this.typeId = Number(this.$route.query.id) + 1;
      }
      this.getStatistic();
    },
  },
};
</script>

<style scoped lang="scss">
.base {
  width: 100%;
  background-color: #fff;
  overflow: hidden;

  .base_head {
    position: relative;
    background-image: url("../../../static/newImg2/medicinal.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    max-height: 1000px;

    .base_head_img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1041px;
      height: 756px;
      background-image: url("../../../static/newImg2/medicinal_map.png");
      background-repeat: no-repeat;
      background-size: cover;
    }

    .base_head_content {
      width: 1100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -40%);

      .base_head_content_title {
        font-weight: bold;
        font-size: 50px;
        color: #ffffff;
        line-height: 61px;
        letter-spacing: 2px;
        text-shadow: 0px 2px 28px rgba(47, 47, 47, 0.31);
        text-align: center;
      }

      .base_head_content_tips {
        margin-top: 26px;
        margin-bottom: 125px;
        font-size: 16px;
        color: #ffffff;
        line-height: 33px;
        text-align: center;
      }

      .base_head_content_num {
        display: flex;
        font-weight: 400;
        font-size: 66px;
        color: #ffffff;
        line-height: 92px;

        .base_head_content_num1 {
          text-align: left;
          width: 33%;
        }

        .base_head_content_num2 {
          text-align: center;
          width: 33%;
        }

        .base_head_content_num3 {
          text-align: right;
          width: 33%;
        }
      }

      .base_head_content_desc {
        display: flex;
        font-weight: 500;
        font-size: 20px;
        color: #ffffff;
        line-height: 30px;
        letter-spacing: 1px;

        .base_head_content_desc1 {
          text-align: left;
          width: 33%;
        }

        .base_head_content_desc2 {
          text-align: center;
          width: 33%;
        }

        .base_head_content_desc3 {
          text-align: right;
          width: 33%;
        }
      }
    }
  }

  .active1 {
    background: linear-gradient(180deg, #267441 0%, #20933d 100%) !important;

    .font1 {
      opacity: 1 !important;
    }
  }

  .base_hegang {
    cursor: pointer;
    // background: pink;
    display: flex;
    height: 110px;

    .font1 {
      width: 382px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: 楷体;
      font-weight: 900;
      font-size: 42px;
      color: #ffffff;
      line-height: 42px;
      letter-spacing: 1px;
    }
  }

  .base_child_info {
    width: 1600px;
    margin: 90px auto;

    .base_child_info_title {
      display: flex;
      margin: 50px 0;
      justify-content: space-between;

      .center_title {
        z-index: 9;
        position: relative;
        margin-top: 0;

        & > img {
          cursor: pointer;
        }

        .center_home {
          font-weight: 400;
          font-size: 16px;
          color: #1d1b19;
          vertical-align: top;
        }
      }

      .slect_list {
        display: flex;

        .el-select {
          margin-right: 52px;
        }
      }

      .base_child_info_title_cn {
        font-size: 31px;
        font-weight: bold;
        color: #000000;
        line-height: 47px;
      }

      .base_child_info_title_en {
        margin-top: 8px;
        font-size: 18px;
        font-weight: 400;
        color: #979797;
        line-height: 27px;
        letter-spacing: 2px;
      }
    }

    .base_child_info_news {
      display: flex;
      width: 1600px;
      margin: 50px auto;

      .base_child_info_news_bg {
        width: 586px;
        position: relative;

        .base_child_info_news_bg_bottom {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 2;
          color: #fff;
          padding: 0 16px;
          font-size: 14px;
          font-weight: bold;
          width: calc(100% - 32px);
          height: 40px;
          line-height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #0000008a;

          .base_child_info_news_bg_bottom_title {
            cursor: pointer;
            width: 70%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .base_child_info_news_bg_bottom_Index {
            float: left;
            color: #000;
            width: 20px;
            height: 18px;
            position: relative;
            line-height: 18px;
            border: 1px solid #ffffff;
            text-align: center;
            margin-right: 5px;
            cursor: pointer;
            background: #ffffff9a;
          }

          .base_child_info_news_bg_bottom_Index:hover {
            color: #fff;
            background: #354a32;
            border: 1px solid #354a32;
          }

          .active {
            color: #fff;
            background: #354a32;
            border: 1px solid #ffffff;
          }
        }
      }

      .base_child_info_news_left {
        margin-left: 40px;
        width: calc(100% - 626px);

        .base_child_info_news_left_title {
          display: flex;
          width: 100%;
          border-bottom: 2px solid #efefef;
          margin-bottom: 20px;

          .base_child_info_news_left_title_name {
            margin-left: 10px;
            padding: 0 34px;
            height: 69px;
            line-height: 69px;
            text-align: center;
            border: 1px solid #ddd;
            border-bottom: 0;
            background: #fff;
            color: #000;
            cursor: pointer;
            font-size: 23px;
          }

          .base_child_info_news_left_title_name:first-child {
            margin-left: 0;
          }

          .active {
            background: #354a32;
            color: #fff;
            transition: all 0.3s ease-in-out 0s;
          }
        }

        .base_child_info_news_left_list {
          cursor: pointer;
          width: 100%;
          display: flex;
          line-height: 46px;
          height: 46px;
          justify-content: space-between;

          .base_child_info_news_left_list_title {
            width: 85%;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            /* 当文本溢出时显示省略号 */
            .base_child_info_news_left_list_title_point {
              width: 4px;
              height: 4px;
              background: #8c8f92;
              opacity: 0.31;
              display: inline-block;
              margin-right: 8px;
              vertical-align: middle;
            }
          }

          .base_child_info_news_left_list_day {
            font-size: 13px;
            color: #0000008c;
          }
        }
      }
    }

    .base_child_info_project {
      margin: 10px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .base_child_info_project_item {
        cursor: pointer;
        width: 49%;
        margin-top: 30px;

        .base_child_info_project_item_img {
          width: 100%;
          height: 362px;
          overflow: hidden;
          position: relative;

          .base_child_info_project_item_img_none {
            position: absolute;
            top: 0;
            left: 0;
            color: #fff;
            font-family: 楷体;
            font-size: 36px;
            font-weight: bold;
            width: 100%;
            height: 100%;
            text-align: center;
            line-height: 355px;
            background-color: rgba(0, 0, 0, 0.6);
          }

          img {
            display: block;
            transition: all 1s;
          }
        }

        .base_child_info_project_item_tips {
          padding: 24px;
          background-color: #fff;
          box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);
          position: relative;

          .base_child_info_project_item_tips_left {
            font-size: 24px;
            font-weight: 400;
            color: #000;
            line-height: 40px;
          }

          .base_child_info_project_item_tips_right {
            font-size: 16px;
            color: #000;
            line-height: 27px;
            // height: 55px;
            overflow: hidden;
            text-overflow: ellipsis;
            // 只要超过宽度就换行，不论中文还是英文
            word-break: break-all;
            //最多展示两行
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }

          .base_child_info_project_item_tips_more {
            width: 500px;
            margin-top: 30px;
            font-size: 12px;
            font-weight: 600;
            color: #157b2f;
            line-height: 20px;
            display: flex;
            align-items: center;

            img {
              vertical-align: middle;
              margin-left: 10px;
              transition: all 0.5s ease-out;
            }
          }

          .base_child_info_project_item_tips_line {
            width: 0;
            height: 2px;
            background-color: #1e9409;
            transition: all 1s;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }

      .base_child_info_project_item:hover {
        .base_child_info_project_item_img img {
          transform: scale(1.1);
        }

        .base_child_info_project_item_tips_line {
          width: 100%;
        }
      }

      .base_child_info_project_item_only {
        display: flex;
        margin-top: 20px;
        cursor: pointer;

        .base_child_info_project_item_img {
          width: 750px;
          height: 362px;
          overflow: hidden;

          img {
            display: block;
          }
        }

        .base_child_info_project_item_tips {
          width: calc(100% - 590px - 48px);
          padding: 24px;
          background-color: #fff;
          box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);

          .base_child_info_project_item_tips_left {
            font-size: 20px;
            color: #000;
            line-height: 40px;
          }

          .base_child_info_project_item_tips_right {
            margin-top: 12px;
            font-size: 16px;
            font-weight: 400;
            color: #000;
            line-height: 27px;
            height: 85px;
            overflow: hidden;
          }

          .base_child_info_project_item_tips_more {
            width: 500px;
            margin-top: 30px;
            font-size: 12px;
            font-weight: 600;
            color: #157b2f;
            line-height: 20px;
            display: flex;
            align-items: center;

            img {
              vertical-align: middle;
              margin-left: 10px;
              transition: all 0.5s ease-out;
            }
          }
        }
      }
    }
  }
}

::v-deep .el-input__inner {
  border: none;
  background-color: #f6f6f6;
}
</style>
